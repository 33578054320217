<h3 i18n="@@misc">Sonstiges</h3>
<div class="card border-0">
  <div class="card-body">
    <div class="row">
      <div class="col-3">
        <span i18n="@@notes">Notizen</span>
        <app-hint i18n="@@notes-hint">
          Hier können Sie <b>Kommentare und Informationen</b> zum Bot hinterlegen, welche anschließend in einem
          <b>eigenen Menüpunkt präsenter</b> darstellt werden. Sie können in diesem Textfeld auch in der
          <b>Markdown-Notation</b> arbeiten.
        </app-hint>
      </div>
      <div class="col">
        <app-code-editor-ai
          [instruction]="this.rss.botConfig.notes"
          [rows]="20"
          (finish)="this.rss.botConfig.notes = $event; this.rss.saveBotConfig()"
        ></app-code-editor-ai>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-3">
        <span>Markdown Cheat Sheet</span>
      </div>
      <div class="col">
        <div class="row">
          <div class="col-2">
            <span i18n="@@md-heading">Überschrift</span>
          </div>
          <div class="col">
            <span># H1</span><br>
            <span>## H2</span><br>
            <span>### H3</span>
          </div>
          <div class="col-2">
            <span i18n="@@md-ordered">Auflistung sortiert</span>
          </div>
          <div class="col">
            <span>1. Text</span><br>
            <span>2. Text</span><br>
            <span>3. Text</span>
          </div>
          <div class="col-2">
            <span i18n="@@md-unordered">Auflistung unsortiert</span>
          </div>
          <div class="col">
            <span>- Text</span><br>
            <span>- Text</span><br>
            <span>- Text</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <span i18n="@@md-strikethrough">Durchgestrichen:</span>
          </div>
          <div class="col">
            <span>~~Text~~</span>
          </div>
          <div class="col-2">
            <span i18n="@@md-bold">Fett:</span>
          </div>
          <div class="col">
            <span>**Text**</span>
          </div>
          <div class="col-2">
            <span i18n="@@md-italic">Kursiv:</span>
          </div>
          <div class="col">
            <span>*Text*</span>
          </div>
        </div>
        <div class="row mt-3">
          <div class="col-2">
            <span i18n="@@md-horizontal">Trennlinie:</span>
          </div>
          <div class="col">
            <span>---</span>
          </div>
          <div class="col-2">
            <span i18n="@@md-link">Link:</span>
          </div>
          <div class="col">
            <span>[text](link)</span>
          </div>
          <div class="col-2">
            <span i18n="@@md-image">Bild:</span>
          </div>
          <div class="col">
            <span>![text](link)</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>