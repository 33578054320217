<ng-template
  #headline
  let-item
>
  <h3>
    <ng-container *ngIf="item ===''">
      <i class="ri-chat-ai-line"></i> <span i18n="@@chat">Chat</span>
    </ng-container>
    <ng-container *ngIf="item ==='/audio'">
      <i class="ri-voice-ai-line"></i> <span i18n="@@audio">Audio</span>
    </ng-container>
    <ng-container *ngIf="item === '/avatar'">
      <i class="ri-video-ai-line"></i> <span i18n="@@avatar">Avatar</span>
    </ng-container>
  </h3>
</ng-template>

<h3 i18n="@@embedding">Einbetten</h3>
<div class="card border-0">
  <div class="card-body">
    <div class="row mt-2">
      <div class="col ">
        <div class="callout callout-secondary">
          <h4 i18n="@@iframe-embedding">Iframe Einbettung</h4>
          <div class="row">
            <div
              class="col-4"
              *ngFor="let item of this.urlSubFixes"
            >
              <ng-container *ngTemplateOutlet="headline; context: { $implicit: item }"></ng-container>
              <textarea
                name=""
                id=""
                rows="6"
                class="form-control lock-insensitive"
                style="word-wrap: normal;"
                readonly
              >
                <iframe
                  src="{{this.getDirectLink()}}{{item}}"
                  width="100%"
                  height="600px"
                  frameborder="0"
                  sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-top-navigation allow-modals allow-downloads"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; microphone; download *; fullscreen; camera; speaker; geolocation"
                  referrerpolicy="strict-origin-when-cross-origin"
                ></iframe></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="callout callout-secondary">
          <h4 i18n="@@direct-link"> Direktlinks</h4>
          <div class="row">
            <div
              class="col-4"
              *ngFor="let item of this.urlSubFixes"
            >
              <ng-container *ngTemplateOutlet="headline; context: { $implicit: item }"></ng-container>
              <input
                type="text"
                class="form-control lock-insensitive"
                readonly
                [value]="this.getDirectLink()+item"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="callout callout-secondary">
          <h4 i18n="@@qr-codes"> Qr-Codes</h4>
          <div class="row g-2">
            <div
              class="col-4 text-center  py-5 "
              *ngFor="let item of this.urlSubFixes"
            ><ng-container *ngTemplateOutlet="headline; context: { $implicit: item }"></ng-container>
              <img
                [src]="this.getQRUrl()+item"
                height="200px"
                alt="QR-Code"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="card border-0">
  <div class="callout callout-secondary">
    <h4 i18n="@@scorm-export"> Scorm 1.2 Export</h4>
    <div class="row">
      <div class="col-4 text-center py-5">
        <button
          class="btn btn-outline-secondary"
          (click)="this.zipService.exportChatBot(this.rss.botConfig)"
        >
          <i class="ri-download-2-fill"></i>
          <span i18n="@@chat-bot-scorm-zip">Chatbot Scorm Zip</span>
        </button>
      </div>
      <div class="col-4 text-center py-5">
        <button
          class="btn btn-outline-secondary"
          (click)="this.zipService.exportAudioBot(this.rss.botConfig)"
        >
          <i class="ri-download-2-fill"></i>
          <span i18n="@@audio-bot-scorm-zip">Audio Bot Scorm Zip</span>
        </button>
      </div>
      <div class="col-4 text-center py-5">
        <button
          class="btn btn-outline-secondary"
          (click)="this.zipService.exportAvatarBot(this.rss.botConfig)"
        >
          <i class="ri-download-2-fill"></i>
          <span i18n="@@avatar-bot-scorm-zip">Avatar Scorm Zip</span>
        </button>
      </div>
    </div>
  </div>
  <div class="callout callout-secondary">
    <h4 i18n="@@config-export">Bot Konfigurationen</h4>
    <div class="row">
      <div class="col-4 text-center py-5">
        <button
          class="btn btn-outline-secondary"
          (click)="this.exportBotConfigJSON(this.rss.botConfig)"
        >
          <i class="ri-download-2-fill"></i>
          <span i18n="@@chat-bot-botconfig-json">Config.json</span>
        </button>
      </div>
    </div>
  </div>
  <div class="callout callout-secondary">
    <h4 i18n="@@generate-import-link">Bot Import Link erzeugen</h4>
    <div class="row">
      <div class="col-4 text-center py-5">
        <button
          (click)="this.exportBot()"
          class="btn btn-outline-secondary"
          i18n="@@generate-link"
        >
          Link generieren
        </button>
      </div>
      <div class="col-5 text-center py-5">
        <input
          *ngIf="this.exportLink"
          type="text"
          class="form-control"
          readonly
          [value]="this.exportLink"
        >
      </div>
    </div>
  </div>
</div>