import { CodeEditorModule } from '@acrodata/code-editor';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CodeEditorAiComponent } from 'src/app/shared/components/ai-bot/utils/code-editor-ai/code-editor-ai.component';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { RouteShareService } from 'src/app/shared/services/route-share.service';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, RouterModule, CodeEditorModule, HintComponent, CodeEditorAiComponent],
  templateUrl: './misc-tab.component.html',
  styleUrl: './misc-tab.component.scss'
})
export class MiscTabComponent {
  constructor(
    public router: Router,
    public rss: RouteShareService
  ) { }
}
