<p>supervisor-threads works!</p>
<!--
  Bitte einfach rein funktional ein NG-FOR auf nen Div setzen bei der darstellung
  ich werde das eh auf Virtual Scroll umbauen ~tiro
-->
<ul class="list-group list-group-flush">
  <li class="list-group-item">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eget turpis sit amet lectus dapibus vehicula in eu
    odio. Integer ut nulla eget felis aliquam fringilla vitae a dui. In convallis diam in odio pulvinar accumsan. Donec
    porttitor magna mauris, volutpat fringilla mi varius et. Proin euismod efficitur augue non commodo. Ut urna velit,
    dignissim in luctus porta, tempor id elit. In quis mi velit. Morbi et sodales nibh. Nullam in dui massa.
    Pellentesque
    lobortis ullamcorper erat quis porta. Donec rutrum libero eget pellentesque pretium. Donec ullamcorper suscipit
    dolor.
    Donec vitae nibh et libero faucibus tincidunt semper at risus. Quisque ut arcu in metus semper fringilla. Ut luctus
    iaculis dapibus.
  </li>
  <li class="list-group-item">
    Sed arcu sapien, vulputate vel felis non, feugiat interdum elit. Mauris id diam odio. Aenean iaculis tortor vitae
    eros
    molestie pellentesque. Duis congue purus erat, id dictum tortor imperdiet ac. Phasellus interdum eget eros a
    dapibus.
    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam efficitur arcu velit,
    sed
    mattis orci lobortis sit amet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
    curae;
    Suspendisse potenti.
  </li>
  <li class="list-group-item">
    Donec semper egestas ante et molestie. Morbi in pellentesque purus, id gravida sem. Cras iaculis venenatis enim, vel
    blandit purus sollicitudin et. Fusce erat risus, efficitur in erat vel, mattis lobortis neque. Suspendisse et est
    varius, sodales magna in, aliquam diam. Maecenas pretium lectus dui, vel molestie orci dignissim ut. Cras congue at
    erat
    nec tincidunt.
  </li>
  <li class="list-group-item">
    Duis hendrerit vulputate gravida. Vivamus luctus, nisl vitae dictum iaculis, massa purus elementum nulla, eget
    aliquet
    lorem tellus in eros. Aliquam ac molestie eros. Donec id egestas turpis, nec pharetra erat. In faucibus sed lacus ac
    condimentum. Quisque dictum commodo orci, ac vestibulum ante facilisis eget. Vestibulum iaculis odio in ligula
    malesuada
    dictum. Quisque facilisis tristique lobortis. Curabitur a dictum sem, ac feugiat arcu. Orci varius natoque penatibus
    et
    magnis dis parturient montes, nascetur ridiculus mus. Quisque dictum non purus non sodales. In malesuada eros ac
    finibus
    rhoncus. Sed convallis semper quam sit amet ullamcorper. Phasellus volutpat ultricies est, id mollis augue finibus
    sit
    amet.
  </li>
  <li class="list-group-item">
    Etiam quis tortor suscipit, dictum est eget, tempus augue. Vivamus eget augue sem. Curabitur sed elit faucibus,
    luctus
    nisl non, consectetur purus. Cras rhoncus nisi vitae ante pellentesque, in sollicitudin tellus sollicitudin. Sed ex
    quam, luctus non ultricies ut, vulputate vel ligula. Integer nec ligula est. Pellentesque malesuada gravida
    ultrices.

  </li>
</ul>