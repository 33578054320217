<ng-template
  #formular
  let-data
>
  <div class="row">
    <div class="col-lg-4">
      <span>
        <i class="ri-key-fill"></i> <ng-container i18n="@@search-key">Suchschlüssel</ng-container>
      </span>
      <input
        class="form-control"
        [(ngModel)]="data.key"
        (ngModelChange)="formularModelChange()"
        [ngModelOptions]="{updateOn:  'blur'}"
      >
    </div>
    <div class="col">
      <span>
        <i class="ri-file-text-line"></i>
        <ng-container i18n="@@value">Wert</ng-container>
      </span>
      <textarea
        class="form-control border-2"
        [(ngModel)]="data.value"
        (ngModelChange)="formularModelChange()"
        [ngModelOptions]="{updateOn:  'blur'}"
      ></textarea>
    </div>
  </div>
  <button
    class="btn btn-primary btn-sm mt-2"
    (click)="this.addItemToList();"
    *ngIf="this.getSelectedItemState() === 'new'"
  > <i class="ri-add-fill"></i>
    <ng-container i18n="@@add">Hinzufügen</ng-container>
  </button>
</ng-template>
<ng-template
  #preconditionForm
  let-data
>
  <div class="dropdown">
    <button
      type="button"
      class="btn dropdown-toggle btn-sm ps-0 border-0"
      [ngClass]="{'disabled': possibleTrunkItemsForConditionSelection(data).length === 0}"
      data-bs-toggle="dropdown"
      aria-expanded="false"
    >
      @if(data.conditions.length === 0){
      <ng-container i18n="@@always-active-title">immer aktiv:</ng-container>
      }
      @if(data.conditions.length > 0){
      <ng-container i18n="@@active-when-title">aktiv wenn:</ng-container>
      }
      <i class="ri-mind-map"></i><span class="caret"></span>
    </button>
    <ul
      class="dropdown-menu"
      role="menu"
      *ngIf="possibleTrunkItemsForConditionSelection(data).length > 0"
    >
      <li
        *ngFor="let currItem of  possibleTrunkItemsForConditionSelection(data)"
        role="menuitem"
        class="dropdown-item cursor-pointer"
        [ngClass]="{'d-none':  this.isSelected(data, currItem)}"
      >
        <a
          class="dropdown-item"
          (click)="this.toggleCondition(data, currItem)"
        >
          {{currItem.key}}</a>
      </li>
    </ul>
  </div>
  @for (conditionEntry of data.conditions; track $index) {
  <div class="row gx-0">
    <div class="col-auto pe-3">
      <i
        class="ri-close-circle-fill cursor-pointer text-danger"
        (click)="this.removeCondition(data, conditionEntry.trunkItemId)"
      ></i>
    </div>
    <div class="col">
      {{ this.getTrunkItemById( conditionEntry.trunkItemId)!.key}}
      <span class="badge btn-outline-primary ">
        <div class="dropdown">
          <span
            style="position: relative;top:-4px"
            dropdownToggle
            type="button"
            class="btn btn-sm dropdown-toggle rounded-pill  btn-outline-primary  py-0"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {{this.conditionTypeToName(conditionEntry.condition)}}
            <span class="caret"></span>
          </span>
          <ul
            class="dropdown-menu"
            role="menu"
          >
            <ng-container *ngFor="let type of conditionTypes">
              <li
                role="menuitem"
                class="cursor-pointer"
                (click)="this.changeConditionEntryType(data, conditionEntry, type)"
              >
                <a class="dropdown-item">
                  {{this.conditionTypeToName(type)}}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </span>
    </div>
  </div>
  }
</ng-template>
<ng-container *ngIf="this.rss.datatrunk">


  <app-admin-back-button i18n="@@configured-databases-overview">Konfigurierte KI-Datenbanken im
    Überblick</app-admin-back-button>
  <div class="row align-items-baseline">
    <div class="col">
      <h3
        class="mt-4"
        i18n="@@edit-database"
      >
        Datenbank Bearbeiten
      </h3>
    </div>
    <div class="col-auto text-end">
      <app-skill-id-input
        [value]="this.rss.datatrunk.skillId"
        (changed)="this.rss.datatrunk.skillId = $event; this.updateDatabase(this.rss.datatrunk)"
      ></app-skill-id-input>
    </div>
  </div>
  <div class="row  mt-3">
    <div class="col-3">
      <span i18n="@@intended-database-use">
        Verwendungszweck der Datenbank
      </span>
    </div>
    <div class="col">
      <input
        class="form-control rag-aitext"
        placeholder=""
        [(ngModel)]="this.rss.datatrunk.trunkDescription"
        (ngModelChange)="this.updateDatabase(this.rss.datatrunk)"
        [ngModelOptions]="{updateOn: 'blur'}"
      >
      <ng-container i18n="@@example-database-use">
        Beispiele: <b>Nachschlagewerk für Fachbegriffe</b>, <b>detaillierte Informationen zu einem Produkt</b>, oder
        <b>die
          häufigsten Fragen und Antworten.</b></ng-container>
    </div>
  </div>
  <div class="row align-items-center mt-3 ">
    <div class="col-3">
      <span i18n="@@database-function-name">Datenbank (Funktionsname)</span>
    </div>
    <div class="col">
      <span>
        {{this.rss.datatrunk.placeholder}}
      </span>
    </div>
  </div>

  <h5
    class="mt-5"
    i18n="@@database-entries"
  >Datenbank Einträge</h5>
  <div
    class="callout callout-info"
    i18n="@@database-entries-description"
  >
    Mit <b>Suchschlüsseln</b> geben Sie dem Bot an, nach welche Art von Einträgen dieser in der Datenbank suchen kann.
  </div>
  <div class="row  mt-4">
    <div class="col-3">
      <span i18n="@@search-key-meaning">
        Bedeutung des Suchschlüssels
      </span>
    </div>
    <div class="col">
      <input
        class="form-control rag-aitext"
        placeholder=""
        [(ngModel)]="this.rss.datatrunk.keyDescription"
        (ngModelChange)="this.updateDatabase(this.rss.datatrunk)"
        [ngModelOptions]="{updateOn: 'blur'}"
      >
      <span i18n="@@search-key-meaning-example">Beispiele: <b>der gesuchte Fachbegriff</b>, <b>die
          Produktbezeichnung</b>,
        oder <b>die Fragestellung.</b></span>
    </div>
  </div>
  <div class="row mt-5">
    <ng-container *ngIf="this.paramForNewForm">
      <div
        class="col p-5"
        style="border-left: 4px solid #00A599;"
      >
        <ng-template
          [ngTemplateOutlet]="formular"
          [ngTemplateOutletContext]="{ $implicit: this.paramForNewForm }"
        >
        </ng-template>
      </div>
    </ng-container>
    <ng-container *ngIf="!this.paramForNewForm && this.mode !== 'import'">
      <div class="col ">
        <button
          class="btn btn-primary btn-sm"
          (click)="this.selectEmptyParam()"
        >
          <i class="ri-add-fill"></i>
          <ng-container i18n="@@new-entry">Neuer Eintrag</ng-container>
        </button>
      </div>
      <div class="col-auto text-end">
        <rag-excel-import
          [config]="this.xlsxConfig"
          [class]="'btn  btn-outline-secondary btn-sm'"
          [demoFileName]="'demo-import'"
          [demoData]="[{key: 'test', value: 'tset'}]"
          [importButton]="this.importButton"
          [downloadButton]="this.downloadButton"
          (import)="this.onExcelInput($event)"
        >
        </rag-excel-import>
        <rag-excel-export
          [class]="'btn  btn-outline-secondary btn-sm ms-1'"
          [config]="this.xlsxConfig"
          [fileName]="'export_'+this.rss.datatrunk!.placeholder"
          [data]="this.rss.datatrunk.items"
          [exportButton]="this.exportButton"
        >
        </rag-excel-export>
      </div>
    </ng-container>
  </div>
  @if (this.mode === 'import') {
  <div class="row align-items-center mt-5">
    <div class="col">
      <h3 i18n="@@import">Import</h3>
    </div>
    <div class="col-auto text-end">
      <button
        class="btn btn-secondary"
        (click)="this.mode = 'edit'"
        i18n="@@cancel"
      >Abbrechen</button>
      <button
        *ngIf="this.importedDataList.length > 0"
        class="btn btn-primary ms-1"
        (click)="this.importGuestList()"
        [disabled]="this.importing"
      >
        <span
          class="spinner-border spinner-border-sm"
          aria-hidden="true"
          *ngIf="importing"
        >
        </span>
        <span *ngIf="!importing"><i class="ri-import-fill"></i></span>
        <span
          role="status"
          i18n="@@import-data"
        >
          Daten importieren
        </span>
      </button>
    </div>
  </div>
  <div class="full-width-table-wrapper mb-5">
    <table class="table table-hover">
      <tbody>
        @for (item of this.importedDataList; track $index) {
        <tr>
          <td>
            <input
              class="form-control form-control-sm"
              [(ngModel)]="item.key"
            >
          </td>
          <td>
            <input
              class="form-control form-control-sm"
              [(ngModel)]="item.value"
            >
          </td>
          <td style="width: 4rem; text-align: center; vertical-align: middle;">
            <button
              class="btn btn-outline-danger btn-sm"
              (click)="this.removeImportedDatabaseItem(item)"
            >
              <i class="ri-close-line"></i>
            </button>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  }
  @if (this.rss.datatrunk.items.length > 0) {
  <div class="row mt-3 mb-3">
    <div class="col pe-0">
    </div>
    <div class="col-auto">
    </div>
  </div>
  <div class="full-width-table-wrapper">
    <table class="table table-hover">
      <tbody>
        @for (item of this.rss.datatrunk.items; track $index) {
        <tr [ngClass]="{'bg-selected-row': this.selectedItem === item}">
          <ng-container *ngIf="this.selectedItem !== item">
            <td style="vertical-align: middle;">
              <div
                style="vertical-align: middle;"
                *ngIf="this.enumTestResult.has(item.key)"
              >
                <div class="callout callout-info bg-white">
                  <div class="row">
                    <div class="col-auto">
                      <span class="badge bg-info">
                        {{this.enumTestResult.get(item.key)?.bewertung}}
                      </span>
                    </div>
                    <div class="col text-info">{{this.enumTestResult.get(item.key)?.grund}}</div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-5">
                  <span
                    class="fakelink"
                    (click)="this.selectedItemIndex = $index;this.paramForNewForm = undefined"
                  >
                    <i class="ri-key-fill"></i>
                  </span>
                  {{ item.key }}
                </div>
                <div class="col">
                  <span
                    class="fakelink"
                    (click)="this.selectedItemIndex = $index;this.paramForNewForm = undefined"
                  >
                    <i class="ri-file-text-line"></i></span>{{ item.value }}
                </div>
              </div>
              <div class="alert  p-0 mt-2 ms-5">
                <ng-template
                  [ngTemplateOutlet]="preconditionForm"
                  [ngTemplateOutletContext]="{ $implicit: item }"
                ></ng-template>
              </div>
            </td>
          </ng-container>
          <ng-container *ngIf="this.selectedItem === item">
            <td class="p-5">
              <ng-template
                [ngTemplateOutlet]="formular"
                [ngTemplateOutletContext]="{ $implicit: item }"
              ></ng-template>
              <div class="ms-5">
                <ng-template
                  [ngTemplateOutlet]="preconditionForm"
                  [ngTemplateOutletContext]="{ $implicit: item }"
                ></ng-template>
              </div>
              <div>
                <div
                  *ngIf="this.selectedItem === item"
                  class="btn btn-primary mt-2"
                  (click)="this.selectedItemIndex = -1 "
                >
                  <i class="ri-checkbox-fill"></i>
                </div>
              </div>
            </td>
          </ng-container>
          <td class="text-end">
            <div
              class="dropdown"
              *ngIf="this.selectedItem !== item"
            >
              <button
                class="btn dropdown-toggle"
                (click)="this.selectedItemIndex = -1; this.paramForNewForm = undefined"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i class="ri-more-fill"></i>
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button
                    type="button"
                    class="dropdown-item cursor-pointer text-primary"
                    (click)="this.selectedItemIndex = $index;this.paramForNewForm = undefined"
                  >
                    <i class="ri-pencil-fill"></i> <ng-container i18n="@@edit">Bearbeiten</ng-container>
                  </button>
                </li>
                <li>
                  <button
                    type="button"
                    class="dropdown-item cursor-pointer text-danger"
                    (click)="this.deleteItemFromList($index)"
                  >
                    <i class="ri-close-large-fill"></i> <ng-container i18n="@@delete">Löschen</ng-container>
                  </button>
                </li>
              </ul>
            </div>
          </td>
        </tr>
        }
      </tbody>
    </table>
  </div>
  <div class="card border-0">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <input
            #enumTestString
            class="form-control form-control-sm  lock-insensitive"
            [attr.disabled]="this.enumTestIsRunning?'disabled':null"
          >
        </div>
        <div class="col-auto">
          <div
            class="btn btn-sm btn-primary  lock-insensitive"
            (click)="this.enumTest(enumTestString.value)"
            [attr.disabled]="this.enumTestIsRunning?'disabled':null"
            i18n="@@test-search-key"
          >
            Suchschlüssel testen
          </div>
        </div>
      </div>
    </div>
  </div>
  }
</ng-container>