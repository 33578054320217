import { Component } from '@angular/core';
import { SupervisorService } from '../supervisor.service';

@Component({
  selector: 'app-supervisor-chat',
  standalone: true,
  imports: [],
  templateUrl: './supervisor-chat.component.html',
  styleUrl: './supervisor-chat.component.scss'
})
export class SupervisorChatComponent {
  constructor(public supervisorService: SupervisorService) { }
}
