import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { BotService } from '../services/bot.service';
import { LoginService } from '../services/login.service';

export const supervisorResolver: ResolveFn<unknown> = async (route, state) => {
  const loginService: LoginService = inject(LoginService);
  const botService: BotService = inject(BotService);
  const supervisorService: BotService = inject(BotService);


  return {};
};
