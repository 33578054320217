<div class="px-3">
  <p>supervisor-chat works!</p>
  <p style="font-family: cursive;">
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eget turpis sit amet lectus dapibus vehicula in eu
    odio. Integer ut nulla eget felis aliquam fringilla vitae a dui. In convallis diam in odio pulvinar accumsan. Donec
    porttitor magna mauris, volutpat fringilla mi varius et. Proin euismod efficitur augue non commodo. Ut urna velit,
    dignissim in luctus porta, tempor id elit. In quis mi velit. Morbi et sodales nibh. Nullam in dui massa.
    Pellentesque
    lobortis ullamcorper erat quis porta. Donec rutrum libero eget pellentesque pretium. Donec ullamcorper suscipit
    dolor.
    Donec vitae nibh et libero faucibus tincidunt semper at risus. Quisque ut arcu in metus semper fringilla. Ut luctus
    iaculis dapibus.

    Sed arcu sapien, vulputate vel felis non, feugiat interdum elit. Mauris id diam odio. Aenean iaculis tortor vitae
    eros
    molestie pellentesque. Duis congue purus erat, id dictum tortor imperdiet ac. Phasellus interdum eget eros a
    dapibus.
    Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nullam efficitur arcu velit,
    sed
    mattis orci lobortis sit amet. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia
    curae;
    Suspendisse potenti.

    Donec semper egestas ante et molestie. Morbi in pellentesque purus, id gravida sem. Cras iaculis venenatis enim, vel
    blandit purus sollicitudin et. Fusce erat risus, efficitur in erat vel, mattis lobortis neque. Suspendisse et est
    varius, sodales magna in, aliquam diam. Maecenas pretium lectus dui, vel molestie orci dignissim ut. Cras congue at
    erat
    nec tincidunt.

    Duis hendrerit vulputate gravida. Vivamus luctus, nisl vitae dictum iaculis, massa purus elementum nulla, eget
    aliquet
    lorem tellus in eros. Aliquam ac molestie eros. Donec id egestas turpis, nec pharetra erat. In faucibus sed lacus ac
    condimentum. Quisque dictum commodo orci, ac vestibulum ante facilisis eget. Vestibulum iaculis odio in ligula
    malesuada
    dictum. Quisque facilisis tristique lobortis. Curabitur a dictum sem, ac feugiat arcu. Orci varius natoque penatibus
    et
    magnis dis parturient montes, nascetur ridiculus mus. Quisque dictum non purus non sodales. In malesuada eros ac
    finibus
    rhoncus. Sed convallis semper quam sit amet ullamcorper. Phasellus volutpat ultricies est, id mollis augue finibus
    sit
    amet.

    Etiam quis tortor suscipit, dictum est eget, tempus augue. Vivamus eget augue sem. Curabitur sed elit faucibus,
    luctus
    nisl non, consectetur purus. Cras rhoncus nisi vitae ante pellentesque, in sollicitudin tellus sollicitudin. Sed ex
    quam, luctus non ultricies ut, vulputate vel ligula. Integer nec ligula est. Pellentesque malesuada gravida
    ultrices.

    Phasellus in libero tempus, ornare enim maximus, mollis tortor. Etiam sagittis luctus mi a dictum. Sed purus justo,
    porta in velit non, lobortis accumsan arcu. Vestibulum mattis ac urna et sagittis. Vestibulum ultrices justo eros,
    in
    varius ligula ultricies eget. Integer nisi quam, fringilla non efficitur dignissim, vehicula et eros. Integer rutrum
    sapien sit amet pretium tempor. Vestibulum dui metus, facilisis sed justo a, accumsan ullamcorper ligula.

    Sed lacinia ex quis magna pellentesque tristique. Cras aliquam justo vitae risus scelerisque feugiat. Suspendisse
    egestas nisi id interdum fermentum. Vestibulum malesuada iaculis elit, et vehicula tellus lacinia vehicula. Integer
    nulla nisi, tincidunt eu dui eget, dictum maximus turpis. Nulla dolor nisi, pharetra a ullamcorper ac, feugiat a
    eros.
    Nam mattis, arcu non congue scelerisque, sapien orci ullamcorper tortor, ut molestie nisl nibh sed turpis. Nunc
    cursus
    velit consectetur tellus placerat, ultricies ultrices velit efficitur.

    Aliquam fermentum, erat vitae porta congue, massa ligula bibendum velit, a suscipit massa dolor quis urna. Integer
    iaculis porta ante, sed commodo mi vestibulum a. Proin tempor massa tincidunt efficitur bibendum. Vestibulum
    scelerisque
    aliquam sem, sit amet tempus nibh tristique fringilla. Suspendisse libero est, ultrices vel accumsan ac, commodo non
    orci. Morbi finibus sapien ac eros faucibus, sit amet posuere leo hendrerit. In rutrum hendrerit urna, consequat
    aliquam
    nunc finibus at. Integer vel erat ultricies, pellentesque dui et, sodales lacus. Sed leo erat, rhoncus sed posuere
    nec,
    pulvinar lacinia magna. Proin semper nulla at libero imperdiet cursus. Interdum et malesuada fames ac ante ipsum
    primis
    in faucibus. Phasellus faucibus aliquet dictum. Suspendisse mattis turpis ut felis gravida, sed posuere enim
    vulputate.
    Phasellus vulputate erat justo. Cras et fermentum urna. Cras laoreet eros in nulla placerat, ut fringilla lectus
    consectetur.

    Aenean fringilla enim sed turpis rhoncus ultricies. Vivamus ac molestie magna. Aenean et gravida elit. Phasellus sit
    amet dignissim magna, sed condimentum lorem. Vivamus vitae maximus metus, a fringilla ex. Phasellus sed placerat ex.
    Pellentesque sollicitudin id mauris in sollicitudin. Pellentesque at pellentesque risus.

    Nam cursus augue eu euismod scelerisque. Mauris lacus massa, gravida consectetur accumsan ut, pharetra sit amet
    erat.
    Cras rhoncus lectus et hendrerit tincidunt. Morbi massa orci, porttitor eu leo sit amet, lacinia luctus tortor.
    Integer
    dignissim sem in tortor consectetur luctus. Suspendisse tempor libero id est luctus semper. Suspendisse fermentum
    pharetra mi finibus tempor. Phasellus quis purus cursus, efficitur mi vitae, aliquet tellus.
  </p>
</div>