import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { SupervisorChatComponent } from "./supervisor-chat/supervisor-chat.component";
import { SupervisorThreadsComponent } from "./supervisor-threads/supervisor-threads.component";
import { SupervisorService } from './supervisor.service';

@Component({
  standalone: true,
  imports: [CommonModule, SupervisorChatComponent, SupervisorThreadsComponent],
  templateUrl: './supervisor-tab.component.html',
  styleUrl: './supervisor-tab.component.scss'
})
export class SupervisorTabComponent {
  constructor(public supervisorService: SupervisorService) { }
}
