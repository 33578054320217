<h3 i18n="@@configuration">Konfiguration</h3>
<div class="card border-0">
  <div class="card-body">
    <div class="row  align-items-center">
      <div class="col-3">
        <span>Model</span>
      </div>
      <div class="col-auto">
        <select
          class="form-select"
          [(ngModel)]="this.rss.botConfig.model"
          [ngModelOptions]="{updateOn: 'blur'}"
          (ngModelChange)="this.rss.saveBotConfig()"
        >
          <option
            *ngFor="let model of gptModels"
            [value]="model"
          >{{ model }}</option>
        </select>
      </div>
    </div>
    <div class="row mt-2 align-items-center">
      <div class="col-3">
        <span i18n="@@temperature">Temperatur</span>
      </div>
      <div class="col-auto pe-0 text-end lock-sensitive">
        <span i18n="@@temperature-precise">Präzise</span>
      </div>
      <div class="col-3 lock-sensitive">
        <ngx-slider
          [(ngModel)]="this.rss.botConfig.temperature"
          [options]="{floor: 0, ceil: 1.5, step: .1}"
          (userChangeEnd)="this.rss.saveBotConfig()"
        ></ngx-slider>
      </div>
      <div class="col-auto ps-0 lock-sensitive">
        <span i18n="@@temperature-creative">Kreativ</span>
      </div>
    </div>
    <div class="row mt-2 align-items-center">
      <div class="col-3">
        <span i18n="@@bot-style">Bot-Style</span>
      </div>
      <div class="col-auto">
        <div class="btn-group">
          <button
            class="btn dropdown-toggle bg-white border"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <span i18n="@@bot-icon">Icon:</span> <i [class]="this.rss.botConfig.icon ?? ''"></i>
          </button>
          <ul
            class="dropdown-menu"
            style="max-height: 300px; overflow-y: auto;"
          >
            <li *ngFor="let icon of this.iconList">
              <a
                class="dropdown-item cursor-pointer"
                (click)="this.rss.botConfig!.icon = icon; this.rss.saveBotConfig()"
              >
                <span i18n="@@bot-icon">Icon:</span> <i [class]="icon"></i>
              </a>
            </li>
          </ul>
          <button
            class="btn dropdown-toggle bg-white border"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          ><span i18n="@@bot-color">Farbe:</span>
            <i
              class="ri-circle-fill"
              [ngStyle]="{'color': this.rss.botConfig.color}"
            ></i>
          </button>
          <ul class="dropdown-menu px-1">
            <li *ngFor="let color of this.colorList">
              <a
                class="dropdown-item cursor-pointer px-0"
                (click)="this.rss.botConfig!.color = color; this.rss.saveBotConfig()"
              >
                <span i18n="@@bot-color">Farbe:</span>
                <i
                  class="ri-circle-fill"
                  [ngStyle]="{'color': color}"
                ></i> {{color}}
              </a>
            </li>
            <li>
              <input
                type="color"
                class="form-control form-control-sm"
                [(ngModel)]="this.rss.botConfig!.color"
                [ngModelOptions]="{updateOn: 'blur'}"
                (ngModelChange)="this.rss.saveBotConfig()"
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mt-2">

  <ul
    class="nav nav-tabs"
    id="myTab"
    role="tablist"
  >
    <li
      class="nav-item"
      role="presentation"
    >
      <button
        class="nav-link active"
        id="home-tab"
        data-bs-toggle="tab"
        data-bs-target="#home"
        type="button"
        role="tab"
        aria-controls="home"
        aria-selected="true"
        i18n="@@custom-css"
      >
        <i class="ri-css3-line"></i>
        Globaler CSS-Code
      </button>
    </li>
    <li
      class="nav-item"
      role="presentation"
    >
      <button
        class="nav-link"
        id="profile-tab"
        data-bs-toggle="tab"
        data-bs-target="#profile"
        type="button"
        role="tab"
        aria-controls="profile"
        aria-selected="false"
        i18n="@@custom-js"
      >
        <i class="ri-javascript-fill"></i>
        Globaler Javascript-Code
      </button>
    </li>
  </ul>
  <div
    class="tab-content"
    id="myTabContent"
  >
    <div
      class="tab-pane fade show active"
      id="home"
      role="tabpanel"
      aria-labelledby="home-tab"
    >
      <app-code-editor-css
        [instruction]="this.rss.botConfig.customCss"
        [rows]="13"
        i18n-placeholder="@@enter-custom-css"
        [placeholder]="'Geben Sie Ihren CSS-Code hier ein ...'"
        (finish)="this.rss.botConfig.customCss = $event; this.rss.saveBotConfig()"
      ></app-code-editor-css>
    </div>
    <div
      class="tab-pane fade"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <app-code-editor-dann
        [code]="this.rss.botConfig.customJs"
        [rows]="13"
        (finish)="this.rss.botConfig.customJs = $event; this.rss.saveBotConfig()"
      >
      </app-code-editor-dann>
    </div>
  </div>
</div>