<h3 i18n="@@supervisor">Supervisor</h3>

<div
  #wrapper
  class="row mt–n5 mx-0"
>
  <div
    class="col-3 p-0 m-0 overflow-y-scroll border-end"
    [ngStyle]="{
      'height': 'calc(100vh - 3rem - ' + wrapper.offsetTop +'px)'
    }"
  >
    <app-supervisor-threads></app-supervisor-threads>
  </div>
  <div
    class="col p-0 m-0 overflow-y-scroll"
    [ngStyle]="{
      'height': 'calc(100vh - 3rem - ' + wrapper.offsetTop +'px)'
    }"
  >
    <app-supervisor-chat></app-supervisor-chat>
  </div>
</div>