import { Component } from '@angular/core';
import { SupervisorService } from '../supervisor.service';

@Component({
  selector: 'app-supervisor-threads',
  standalone: true,
  imports: [],
  templateUrl: './supervisor-threads.component.html',
  styleUrl: './supervisor-threads.component.scss'
})
export class SupervisorThreadsComponent {
  constructor(public supervisorService: SupervisorService) { }
}
