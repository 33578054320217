import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ChatbotConfig } from '@reflact/ai-types';

import { CodeEditor } from '@acrodata/code-editor';
import dayjs from 'dayjs';
import { saveAs } from "file-saver";
import { FormForBooleanComponent } from 'src/app/shared/components/ai-bot/utils/form-for-boolean/form-for-boolean.component';
import { HintComponent } from 'src/app/shared/components/ai-bot/utils/hint/hint.component';
import { LanguageService } from 'src/app/shared/services/language.service';
import { RouteShareService } from 'src/app/shared/services/route-share.service';
import { SharetokenService } from 'src/app/shared/services/sharetoken.service';
import { ToastErrorTitle, ToastInfoTitle, ToastrService, ToastSuccessTitle } from 'src/app/shared/services/toastr.service';
import { getExportFileName, ZipexportService } from 'src/app/shared/services/zipexport/zipexport.service';


@Component({
  standalone: true,
  imports: [CommonModule, FormForBooleanComponent, HintComponent, CodeEditor],
  templateUrl: './embed-tab.component.html',
  styleUrl: './embed-tab.component.scss'
})
export class EmbedTabComponent {
  public exportLink: string = '';
  public urlSubFixes = ['', '/audio', '/avatar'];
  public hasBeenExportButtonPressed: boolean = false;

  constructor(
    private toastr: ToastrService,
    private SharetokenService: SharetokenService,
    public zipService: ZipexportService,
    private langService: LanguageService,
    public rss: RouteShareService
  ) { }


  public getQRUrl(): string {
    return '/api/qrcode?text=' + this.getDirectLink();
  }


  public exportBotConfigJSON(botConfig: ChatbotConfig) {
    botConfig.name = botConfig.name + "_" + dayjs().format('YYYY-MM-DD');
    const blob = new Blob([JSON.stringify(botConfig)], { type: "text/plain;charset=utf-8" });
    saveAs(blob, getExportFileName(botConfig.name, 'Config', 'rai'), { autoBom: true });
  }

  public getDirectLink(): string {
    return window.location.origin + this.langService.language + '/bot/' + this.rss.botConfig._id;
  }




  public async exportBot() {
    const token = await this.SharetokenService.getShareToken(this.rss.botConfig._id);
    if (token) {
      this.exportLink = (window.location.href).split('/').slice(0, 3).join('/') + "/bot-import/" + token.shareToken;
      this.toastr.success(ToastSuccessTitle.LINK_GENERATED);
      this.hasBeenExportButtonPressed = true;
    } else {
      this.toastr.error(ToastErrorTitle.LINK_GENERATED);
    }
  }

  public copyToClipboard(element: HTMLInputElement) {
    element.select();
    void navigator.clipboard.writeText(element.value);
    element.setSelectionRange(0, 0);
    this.toastr.info(ToastInfoTitle.LINK_COPIED);
  }
}
